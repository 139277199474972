var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('transition',{attrs:{"name":"fade"}},[(_vm.passwordSuccess === false)?[_c('div',{staticClass:"container mt--8 pb-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bg-secondary border-0 mb-0"},[(_vm.loading)?[_c('div',{staticClass:"mt-4 mb-4"},[_c('v-loader',{attrs:{"loading":_vm.loading}})],1)]:[_c('div',{staticClass:"card-header bg-transparent"},[(_vm.action == 'create')?[_vm._v(" "+_vm._s(_vm.$t( "login.create_password_title" ))+" ")]:_vm._e(),(_vm.action == 'reset')?[_vm._v(" "+_vm._s(_vm.$t("login.reset_password_title"))+" ")]:_vm._e()],2),(_vm.tokenVerified === false)?_c('div',{staticClass:"card-body px-lg-5 py-lg-5"},[_c('div',{staticClass:"alert alert-danger alert-outline"},[_vm._v(" "+_vm._s(_vm.$t( "login.your_request_has_expired_or_does_not_exist" ))+" ")])]):_c('div',{staticClass:"card-body px-lg-5"},[(_vm.action == 'create')?_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t( "login.create_password_intro" ))+" ")]):_vm._e(),(_vm.action == 'reset')?_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t("login.reset_password_intro"))+" ")]):_vm._e(),_c('FormulateForm',{attrs:{"errors":_vm.errors},on:{"submit":_vm.onSubmit}},[_c('FormulateInput',{attrs:{"autocomplete":"new-password","type":"password","name":"password","label":_vm.$t('login.new_password'),"placeholder":_vm.$t('login.password'),"validation":"required","validation-name":_vm.$t('password.password')},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('FormulateInput',{attrs:{"autocomplete":"new-password","type":"password","name":"confirm","label":_vm.$t('login.confirm_password'),"placeholder":_vm.$t('login.confirm_password'),"validation":"passwordsMatch","validation-name":_vm.$t('password.password'),"validation-rules":{
                                                passwordsMatch:
                                                    _vm.rulePasswordsMatch,
                                            },"validation-messages":{
                                                passwordsMatch: _vm.$t(
                                                    'password.passwords_do_not_match'
                                                ),
                                            }},model:{value:(_vm.model.confirm),callback:function ($$v) {_vm.$set(_vm.model, "confirm", $$v)},expression:"model.confirm"}}),_c('v-password-score',{attrs:{"score":_vm.passwordScore,"feedback":_vm.passwordFeedback}}),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"disabled":!_vm.isValid,"type":"primary","native-type":"submit","loading":_vm.buttonLoading}},[(
                                                        _vm.action == 'create'
                                                    )?[_vm._v(" "+_vm._s(_vm.$t( "login.create_account" ))+" ")]:_vm._e(),(_vm.action == 'reset')?[_vm._v(" "+_vm._s(_vm.$t( "login.reset_password" ))+" ")]:_vm._e()],2)],1)],1)],1)]],2)])])])]:[_c('div',{staticClass:"container mt--8 pb-5"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"card bg-secondary border-0 mb-0"},[_c('div',{staticClass:"card-header bg-transparent"},[(_vm.action == 'create')?[_vm._v(" "+_vm._s(_vm.$t("login.create_password"))+" ")]:_vm._e(),(_vm.action == 'reset')?[_vm._v(" "+_vm._s(_vm.$t("login.reset_password"))+" ")]:_vm._e()],2),_c('div',{staticClass:"card-body px-lg-5 py-lg-5"},[(_vm.action == 'reset')?_c('p',[_vm._v(" "+_vm._s(_vm.$t( "password.password_changed_success" ))+" ")]):_vm._e(),(_vm.action == 'create')?_c('p',[_vm._v(" "+_vm._s(_vm.$t( "password.password_created_success" ))+" ")]):_vm._e(),_c('router-link',{staticClass:"btn btn-lg btn-primary mt-4",attrs:{"to":"/login"}},[_vm._v(_vm._s(_vm.$t("login.go_to_login_page")))])],1)])])])])]],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header py-7"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header-body text-center mb-7"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-8 px-5"},[_c('img',{staticStyle:{"max-width":"200px"},attrs:{"src":"/img/logo/meg-logo-white.png"}})])])])])])}]

export { render, staticRenderFns }