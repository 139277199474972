<script>
import LoginService from "@/services/LoginService";

export default {
    data() {
        return {
            loading: false,
            error: false,
            success: false,
            model: {
                email: "",
            },
        };
    },
    computed: {},
    methods: {
        async onSubmit() {
            try {
                const r = await LoginService.passwordReset(this.model.email);
                this.success = true;
            } catch (e) {
                this.error = true;
            }
        },
    },
};
</script>


<template>
    <div>
        <!-- Header -->
        <div class="header py-7">
            <div class="container">
                <div class="header-body text-center mb-7">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                            <img src="/img/logo/meg-logo-white.png" style="max-width: 200px" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container mt--8 pb-5">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-7">
                    <div
                        class="card bg-secondary border-0 mb-0"
                        v-if="success === false"
                    >
                        <div class="card-header bg-transparent">
                            {{ $t("login.forgot_password") }}
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <div class="alert alert-danger" v-if="error">
                                {{ $t("login.technical_error_occurred") }}
                            </div>

                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Email"
                                        :rules="{ required: true, email: true }"
                                        prepend-icon="ni ni-email-83"
                                        :placeholder="$t('login.email')"
                                        v-model="model.email"
                                    >
                                    </base-input>

                                    <div class="text-center">
                                        <base-button
                                            type="primary"
                                            native-type="submit"
                                            class="my-4"
                                            >{{
                                                $t("login.send_reminder_email")
                                            }}</base-button
                                        >
                                    </div>
                                </form>
                            </validation-observer>
                        </div>
                    </div>
                    <div class="card bg-secondary border-0 mb-0" v-else>
                        <div class="card-header bg-transparent">
                            {{ $t("login.forgot_password") }}
                        </div>
                        <div class="card-body px-lg-5 py-lg-5">
                            <div class="alert alert-success alert-outline" >
                                {{ $t("login.password_reset_check_your_email") }}
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 text-center">
                            <router-link to="/login" class="text-light"
                                ><small>{{
                                    $t("login.login_page")
                                }}</small></router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
